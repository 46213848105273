




import { defineComponent, computed } from "@vue/composition-api"

import { useClients } from "@/_use/client"

import CacheSelector from "./CacheSelector.vue"

export default defineComponent({
  name: "ClientSelect",

  components: {
    CacheSelector,
  },

  props: {
    value: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, ctx) {
    const attrs = computed(() => ctx.attrs)

    const clientId = computed({
      get: () => {
        return props.value
      },
      set: (newVal: number) => {
        ctx.emit("input", newVal)
      },
    })

    return { clientId, attrs, useClients }
  },
})
