











import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { useApiCall } from "@/_use/apiCall"
import {
  deleteUser,
  DeleteUserRequest,
  DeleteUserResponse,
} from "@/_service/user"

import { useDialogUtils } from "@/component/Dialog.ts"

import DeleteDialog from "@/component/DeleteDialog.vue"

export default defineComponent({
  name: "UserDeleteDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },

  components: { DeleteDialog },

  setup(props, { emit }) {
    const { t } = useI18n(
      require.context("../", true, /UserList\.[\w-]+\.(json|ya?ml)$/i)
    )

    const { closeDialog, dialog } = useDialogUtils(props, emit)

    const name = computed(() => props.name)

    const submittedParameter = reactive({ userName: name.value })

    watch(name, (newValue) => {
      submittedParameter.userName = newValue
    })

    const { errorMessage, isLoading, onSubmit, reset } = useApiCall<
      DeleteUserRequest,
      DeleteUserResponse
    >(deleteUser, t, submittedParameter, () => {
      dialog.value = false
      emit("deleted", name.value)
    })

    return {
      dialog,
      t,
      isLoading,
      onSubmit,
      errorMessage,
      onCancel: () => closeDialog(reset),
    }
  },
})
