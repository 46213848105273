




import { defineComponent, PropType } from "@vue/composition-api"

import { useClients } from "@/_use/client"

import StoreObjectDisplay from "./StoreObjectDisplay.vue"

export default defineComponent({
  name: "ClientName",

  props: {
    clientId: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
  },

  components: {
    StoreObjectDisplay,
  },

  setup() {
    return {
      useClients,
    }
  },
})
