













import { computed, defineComponent, PropType } from "@vue/composition-api"

import { createDefaultPermissions, PermissionMap } from "@/_service/user"
import { useI18n } from "@/_i18n"

const icons = {
  admin: "mdi-cog",
  readOnly: "mdi-pencil",
  accounting: "mdi-currency-usd",
}

export default defineComponent({
  name: "PermissionIcons",
  props: {
    permissions: {
      type: Object as PropType<PermissionMap>,
      default: createDefaultPermissions,
    },
  },

  setup(props) {
    const { t } = useI18n(
      require.context("./", true, /Permission\.[\w-]+\.(json|ya?ml)$/i)
    )

    const items = computed(() => {
      const permissionKeys = Object.keys(createDefaultPermissions()) as Array<
        keyof PermissionMap
      >
      return permissionKeys.map((key) => ({
        key,
        tooltip: t(`tooltip.${key}.${!!props.permissions[key]}`),
        icon: icons[key],
        color: props.permissions[key]
          ? key === "readOnly"
            ? "grey"
            : "success"
          : key === "readOnly"
          ? "success"
          : "grey",
      }))
    })

    return {
      items,
    }
  },
})
