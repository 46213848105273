


















































import {
  computed,
  defineComponent,
  reactive,
  Ref,
  ref,
} from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { listUsers, ListUsersRequest, User } from "@/_service/user"
import { useReactiveApiCall } from "@/_use/apiCall"
import { useRouteTitle } from "@/_use/routeTitle"

import ActionMenu from "@/component/ActionMenu.vue"
import { Action, useActions } from "@/component/ActionMenu"
import ClientName from "@/component/ClientName.vue"
import ClientSelect from "@/component/ClientSelect.vue"
import Loader from "@/component/Loader.vue"
import PermissionIcons from "@/component/PermissionIcons.vue"
import SuccessAlert from "@/component/SuccessAlert.vue"

import UserDeleteDialog from "./component/UserDeleteDialog.vue"

export default defineComponent({
  name: "UserList",

  components: {
    ActionMenu,
    ClientName,
    ClientSelect,
    Loader,
    PermissionIcons,
    SuccessAlert,
    UserDeleteDialog,
  },

  setup(_, { root }) {
    const { t } = useI18n(
      require.context("./", true, /UserList\.[\w-]+\.(json|ya?ml)$/i)
    )

    useRouteTitle(() => t("title"))

    const isDeleteDialogVisible = ref(false)
    const nameOfUserToDelete = ref("")

    const {
      errorMessage,
      hasErrors: isErrorVisible,
      isLoading,
      refresh,
      resultData: users,
      submittedParameter: listData,
    } = useReactiveApiCall<ListUsersRequest, Array<User>>(listUsers, {
      clientId: undefined,
    })

    const headers = computed(() => {
      return [
        {
          text: t("client"),
          value: "client",
        },
        {
          text: t("header.name"),
          value: "userName",
        },
        {
          text: t("displayName"),
          value: "displayName",
        },
        {
          text: t("header.emailAddress"),
          value: "emailAddress",
        },
        {
          text: t("header.permissions"),
          value: "permissions",
          sortable: false,
        },
        {
          text: t("header.actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ]
    })

    const actionMap = new Map<Action, (id: string) => void>()
    actionMap.set(Action.Delete, (id: string) => {
      const user = (users.value as Array<User>)?.find(
        (it) => it.userName === id
      )
      if (user) {
        isDeleteDialogVisible.value = true
        nameOfUserToDelete.value = user.userName
      }
    })
    actionMap.set(Action.Edit, (id: string) => {
      root.$router.push({ name: "user.update", params: { id } })
    })

    const { onAction, actions } = useActions<User, string>(
      users as Ref<Array<User>>,
      (user: User) => user.userName,
      () => [/*[Action.View, true],*/ [Action.Edit], [Action.Delete]],
      actionMap
    )

    const successAlert = reactive<{
      value: boolean
      msgKey?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: { [key: string]: any }
    }>({
      value: false,
      msgKey: undefined,
      params: undefined,
    })

    const onDeleted = (name: string) => {
      successAlert.msgKey = "deleteDialog.success"
      successAlert.params = { name }
      successAlert.value = true
      refresh()
    }

    return {
      actions,
      errorMessage,
      headers,
      isDeleteDialogVisible,
      isErrorVisible,
      isLoading,
      listData,
      nameOfUserToDelete,
      onAction,
      onDeleted,
      successAlert,
      t,
      users,
    }
  },
})
